import React, { Fragment } from 'react';
import "../styles/recursos.scss";
import Footer from '../components/footer';
import Header from '../components/header';
import TelaAgendamentos from '../assets/telaAgendamentos.png';
import TelaGestao from '../assets/telaGestao.png';
// import TelaHubLinks from '../assets/telaHublinks.png';
import TelaAgenda from '../assets/telaAgendar.png';
import TelaRelatorios from '../assets/telaRelatorios.png';
import TelaFinanceiro from '../assets/telaFinanceiro.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleRight, faArrowsRotate, faMobileScreen } from '@fortawesome/free-solid-svg-icons';
import { faThumbsUp } from '@fortawesome/free-regular-svg-icons';

function RecursosScreen () {
    document.title = `Recursos - BookingHub`;

    return (
        <Fragment>
            <Header/>
            <main id='page-recursos'>
                <section id='section-recursos-introduct'>
                    <h1><span>Recursos</span> que irão fazer toda a <span>diferença</span></h1>
                    <h4>Uma plataforma repleta de funcionalidades é o que você precisa para potencializar sua produtividade.</h4>
                    <ul>
                        <li>
                            <FontAwesomeIcon icon={faMobileScreen}/>
                            <h3>Acesso multiplataforma</h3>
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faThumbsUp}/>
                            <h3>Gestão simplificada</h3>
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faArrowsRotate}/>
                            <h3>Atualizações frequentes</h3>
                        </li>
                    </ul>
                    <button onClick={() => window.scroll({top: document.getElementById("section-recursos-agendamentos").offsetTop - document.querySelector("header").offsetHeight, behavior: "smooth"})}>Conhecer todos os recursos <span><FontAwesomeIcon icon={faAngleDown}/></span></button>
                </section>
                {/* <section id='section-recursos-benefits'> */}
                    {/* <ul>
                        <li>
                            <FontAwesomeIcon icon={faCalendarCheck}/>
                            <h3>Agendamentos facilitados</h3>
                            <h6>Tenha seus agendamentos na palma da mão. Conte com a plataforma para visualizar horários, serviços a serem feitos, funcionário responsável e muito mais!</h6>
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faChartLine}/>
                            <h3>Relatórios do negócio</h3>
                            <h6>Conte com relatórios completos sobre o seu negócio, desde a quantidade de agendamentos realizados até a quantidade de serviço realizado por cada colaborador.</h6>
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faFileInvoiceDollar}/>
                            <h3>Gestão financeira</h3>
                            <h6>Acompanhe todo o faturamento de sua empresa com os agendamentos feitos na plataforma, tenha toda a facilidade na hora de acompanhar seu faturamento.</h6>
                        </li>
                    </ul> */}
                        {/* <li>
                            <FontAwesomeIcon icon={faCalendarCheck}/>
                            <h3>Agendamentos facilitados</h3>
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faGear}/>
                            <h3>Gestão completa</h3>
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faUsersGear}/>
                            <h3>Gestão de colaboradores</h3>
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faLink}/>
                            <h3>HubLinks</h3>
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faChartLine}/>
                            <h3>Relatórios do negócio</h3>
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faFileInvoiceDollar}/>
                            <h3>Gestão financeira</h3>
                        </li> */}
                {/* </section> */}
                <section id='section-recursos-agendamentos'>
                    <img src={TelaAgendamentos} alt='Uma imagem mostrando como é a tela de agendamentos da plataforma'/>
                    <div className='content-section-recursos'>
                        <h2>Agendamentos simplificados</h2>
                        <h3>Tenha seus agendamentos na palma da mão. Conte com a plataforma para visualizar horários, serviços a serem feitos, funcionário responsável por cada agendamento, além do filtro de agendamentos para visualizar de forma rápida com base nas definições escolhidas.</h3>
                    </div>
                </section>
                <section id='section-recursos-gestao'>
                    <div className='content-section-recursos'>
                        <h2>Gestão completa</h2>
                        <h3>Cadastre novos serviços, novos colaboradores, administre todas as informações, podendo alterar, excluir e criar ainda mais. Use o gestor para editar também informações sobre a empresa, alterar foto de perfil e muito mais, tudo de forma simples e intuitiva.</h3>
                    </div>
                    <img src={TelaGestao} alt='Uma imagem mostrando como é a tela de gestão da plataforma'/>
                </section>
                {/* <section id='section-recursos-hublinks'>
                    <img src={TelaHubLinks} alt='Uma imagem mostrando como é a tela do HubLinks na plataforma'/>
                    <div className='content-section-recursos'>
                        <h2>HubLinks</h2>
                        <h3>O HubLinks é a mais nova ferramenta criada por nós, uma ferramenta de criação de árvores de links para você divulgar nas suas redes sociais. Com o HubLinks você pode adicionar links úteis, como suas redes sociais, whatsapp comercial e o que mais desejar, além de ter as informações de sua empresa exibidas por lá.</h3>
                    </div>
                </section> */}
                <section id='section-recursos-relatorios'>
                    <img src={TelaRelatorios} alt='Uma imagem mostrando como é a tela de relatórios da plataforma'/>
                    <div className='content-section-recursos'>
                        <h2>Relatórios do negócio</h2>
                        <h3>Relatórios personalizados para você ficar por dentro de tudo que está acontecendo com seu negócio. Tenha desde a quantidade de atendimentos que cada colaborador realizou até a exibição de todos os dados de um dia em específico, tudo de forma rápida e na palma de sua mão.</h3>
                    </div>
                </section>
                <section id='section-recursos-agenda'>
                    <div className='content-section-recursos'>
                        <h2>Agenda Rápida</h2>
                        <h3>Imagine uma ferramenta onde seus clientes podem agendar seus próprios horários de forma simples, a qualquer hora e em qualquer lugar. Além disso, a agenda rápida otimiza o trabalho da sua equipe, permitindo que você foque no que realmente importa: crescer e melhorar seu negócio.</h3>
                    </div>
                    <img src={TelaAgenda} alt='Uma imagem mostrando como é a tela de agendamentos para clientes na plataforma'/>
                </section>
                <section id='section-recursos-financeiro'>
                    <img src={TelaFinanceiro} alt='Uma imagem mostrando como é a tela de gestão financeira da plataforma'/>
                    <div className='content-section-recursos'>
                        <h2>Gestão financeira</h2>
                        <h3>Conte conosco para te ajudar a facilitar a gestão de finanças do seu negócio. Calcule comissões, cadastre contas de entrada e saída, tenha informações importantes de forma rápida, tudo isso em um único lugar.</h3>
                    </div>
                </section>
                <section className='section-cta'>
                    <div>
                        <h2>Combine facilidade com produtividade e saia na frente!</h2>
                        <h3>Tenha acesso à todos esses recursos sem custo nenhum por 15 dias</h3>
                        <a href='/contratar' target='_blank'>Conhecer gratuitamente <span><FontAwesomeIcon icon={faAngleRight}/></span></a>
                    </div>
                </section>
            </main>
            <Footer/>
        </Fragment>
    );
};
   
export default RecursosScreen;