import React, { Fragment, useEffect, useState } from 'react';
import "../styles/planos.scss";
import Footer from '../components/footer';
import Header from '../components/header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCheckCircle, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { NumericFormat } from 'react-number-format';

function PlanosScreen () {
    document.title = `Planos - BookingHub`;
    // const navigate = useNavigate();
    const [planos, setPlanos] = useState();
    const [tipoPlano, setTipoPlano] = useState('mensal');

    async function getPlanos() {
        const res = await axios.get('https://api.bookinghub.com.br/site/planos');
        setPlanos(res.data);
    };

    useEffect(() => {
        getPlanos();
    }, []);

    function ordemPreco(a, b) {
        return a.valores.mensal < b.valores.mensal ? -1 : a.valores.mensal > b.valores.mensal ? 1 : 0;
    };

    return (
        <Fragment>
            <Header/>
            <main id='page-planos'>
                <section id='section-planos-planos'>
                    <h1>Tudo que <span>você precisa</span>, por um preço <span>acessível</span></h1>
                    <h4>Explore o melhor dos agendamentos online, escolha o plano que melhor se encaixe no seu negócio!</h4>
                    <div className='type-billing-plan'>
                        <div className={tipoPlano === 'mensal' ? 'billing-plan-active' : ""} onClick={() => setTipoPlano('mensal')}>Mensal</div>
                        <div className={tipoPlano === 'anual' ? 'billing-plan-active' : ""} onClick={() => setTipoPlano('anual')}>Anual</div>
                    </div>
                    {planos &&
                        <ul>
                            {planos.sort(ordemPreco).map((item, index) => (
                                <li key={index} className={item.caracteristica ? 'plan-destaque' : ""}>
                                    {item.caracteristica ? <div className='card-plan'>{item.caracteristica}</div> : null}
                                    <h5>{item.nome}</h5>
                                    {/* {tipoPlano === 'mensal' ?
                                        <NumericFormat value={item.valores.mensal} displayType="text" decimalScale={2} decimalSeparator="," thousandSeparator="." prefix='R$' fixedDecimalScale renderText={(value) => <h5>{value}<span>/mês</span></h5>}/>
                                        // <h6>R$99,90<span>/mês</span></h6>
                                    : 
                                        <NumericFormat value={item.valores.anual / 12} displayType="text" decimalScale={2} decimalSeparator="," thousandSeparator="." prefix='R$' fixedDecimalScale renderText={(value) => <h5>{value}<span>/mês</span></h5>}/>
                                        // <h6>R$84,90<span>/mês*</span></h6>
                                    } */}
                                    <NumericFormat value={tipoPlano === 'mensal' ? item.valores.mensal : item.valores.anual / 12} displayType="text" decimalScale={2} decimalSeparator="," thousandSeparator="." prefix='R$' fixedDecimalScale renderText={(value) => <h6>{value}<span>/mês{tipoPlano === 'anual' && "*"}</span></h6>}/>
                                    <a href={`/contratar?planoSelecionado=${item._id}`} target='_blank'>Contrate agora</a>
                                    <div className='content-benefits-plan'>
                                        <div>
                                            <FontAwesomeIcon icon={faCheckCircle}/>
                                            <p>Agendamentos ilimitados</p>
                                        </div>
                                        <div>
                                            <FontAwesomeIcon icon={faCheckCircle}/>
                                            <p>Acesso em multiplataformas</p>
                                        </div>
                                        <div>
                                            <FontAwesomeIcon icon={faCheckCircle}/>
                                            <p>{item.beneficios.colaboradores} Profissionais</p>
                                        </div>
                                        <div>
                                            <FontAwesomeIcon icon={faCheckCircle}/>
                                            <p>{item.beneficios.servicos} Serviços</p>
                                        </div>
                                        <div>
                                            <FontAwesomeIcon icon={item.beneficios.permitir_agendamento ? faCheckCircle :faXmarkCircle}/>
                                            <p>Agenda rápida</p>
                                        </div>
                                        {/* <div>
                                            <FontAwesomeIcon icon={item.beneficios.hublinks ? faCheckCircle :faXmarkCircle}/>
                                            <p>HubLinks</p>
                                        </div> */}
                                        <div>
                                            <FontAwesomeIcon icon={item.beneficios.relatorios ? faCheckCircle :faXmarkCircle}/>
                                            <p>Relatórios do negócio</p>
                                        </div>
                                        <div>
                                            <FontAwesomeIcon icon={item.beneficios.financeiro ? faCheckCircle :faXmarkCircle}/>
                                            <p>Gestão financeira</p>
                                        </div>
                                        {/* <div>
                                            <FontAwesomeIcon icon={faXmarkCircle}/>
                                            <p>Aviso via SMS e E-mail</p>
                                        </div> */}
                                    </div>
                                </li>
                            ))}
                            {/* <li>
                                <h5>Básico</h5>
                                {tipoPlano === 'mensal' ?
                                <h6>R$99,90<span>/mês</span></h6>
                                : 
                                    <h6>R$84,90<span>/mês*</span></h6>
                                }
                                <a href='/contratar?planoSelecionado=basico' target='_blank'>Contrate agora</a>
                                <div className='content-benefits-plan'>
                                    <div>
                                        <FontAwesomeIcon icon={faCheckCircle}/>
                                        <p>Agendamentos ilimitados</p>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon icon={faCheckCircle}/>
                                        <p>Acesso em multiplataformas</p>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon icon={faCheckCircle}/>
                                        <p>2 Colaboradores</p>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon icon={faCheckCircle}/>
                                        <p>4 Serviços</p>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon icon={faXmarkCircle}/>
                                        <p>HubLinks</p>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon icon={faXmarkCircle}/>
                                        <p>Relatórios do negócio</p>
                                    </div> */}
                                    {/* <div>
                                        <FontAwesomeIcon icon={faXmarkCircle}/>
                                        <p>Controle financeiro</p>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon icon={faXmarkCircle}/>
                                        <p>Aviso via SMS e E-mail</p>
                                    </div> */}
                                {/* </div>
                            </li> */}
                            {/* <li>
                                <div className='card-plan'>Mais procurado</div>
                                <h5>Plus</h5>
                                {tipoPlano === 'mensal' ?
                                <h6>R$149,90<span>/mês</span></h6>
                                : 
                                <h6>R$124,90<span>/mês*</span></h6>
                                }
                                <a href='/contratar?planoSelecionado=plus' target='_blank'>Contrate agora</a>
                                <div className='content-benefits-plan'>
                                    <div>
                                        <FontAwesomeIcon icon={faCheckCircle}/>
                                        <p>Agendamentos ilimitados</p>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon icon={faCheckCircle}/>
                                        <p>Acesso em multiplataformas</p>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon icon={faCheckCircle}/>
                                        <p>4 Colaboradores</p>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon icon={faCheckCircle}/>
                                        <p>8 Serviços</p>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon icon={faCheckCircle}/>
                                        <p>HubLinks</p>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon icon={faCheckCircle}/>
                                        <p>Relatórios do negócio</p>
                                    </div> */}
                                    {/* <div>
                                        <FontAwesomeIcon icon={faXmarkCircle}/>
                                        <p>Controle financeiro</p>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon icon={faXmarkCircle}/>
                                        <p>Aviso via SMS e E-mail</p>
                                    </div> */}
                                {/* </div>
                            </li> */}
                            {/* <li>
                                <h5>Profissional</h5>
                                {tipoPlano === 'mensal' ?
                                <h6>R$249,90<span>/mês</span></h6>
                                : 
                                <h6>R$204,90<span>/mês*</span></h6>
                                }
                                <button>Contrate agora</button>
                                <div className='content-benefits-plan'>
                                    <div>
                                        <FontAwesomeIcon icon={faCheckCircle}/>
                                        <p>Agendamentos ilimitados</p>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon icon={faCheckCircle}/>
                                        <p>Acesso em multiplataformas</p>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon icon={faCheckCircle}/>
                                        <p>8 Colaboradores</p>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon icon={faCheckCircle}/>
                                        <p>20 Serviços</p>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon icon={faCheckCircle}/>
                                        <p>HubLinks</p>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon icon={faCheckCircle}/>
                                        <p>Relatórios do negócio</p>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon icon={faCheckCircle}/>
                                        <p>Controle financeiro</p>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon icon={faCheckCircle}/>
                                        <p>Aviso via SMS e E-mail</p>
                                    </div>
                                </div>
                            </li> */}
                        </ul>
                    }
                    <div className='info-billing-plan'>
                        <p>*Os valores apresentados na aquisição de planos anuais, são uma referência de valor pago via PIX e equivalente ao se fosse pago mensalmente.</p>
                        <p>**Os planos anuais devem ser pagos integralmente na contração via PIX, pagamentos por cartão de crédito podem ser parcelados em até 3x.</p>
                    </div>
                </section>
                <section className='section-cta'>
                    <div>
                        <h2>Ainda com dúvidas? Experimente 15 dias grátis!</h2>
                        <h3>Explore a plataforma sem custo nenhum por 2 semanas</h3>
                        <a href='/contratar' target='_blank'>Conhecer gratuitamente <span><FontAwesomeIcon icon={faAngleRight}/></span></a>
                    </div>
                </section>
            </main>
            <Footer/>
        </Fragment>
    );
};
   
export default PlanosScreen;