import { useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import './App.scss';
import HomeScreen from './screens/homeScreen';
import PlanosScreen from './screens/planosScreen';
import RecursosScreen from './screens/recursosScreen';
import ContatoScreen from './screens/contatoScreen';
import ContratarScreen from './screens/contratarScreen';
import FaqScreen from './screens/faqScreen';

function App() {
  useEffect(() => {
      window.history.scrollRestoration = 'manual'
  },[]);

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<HomeScreen/>}/>
        <Route exact path='/planos-e-precos' element={<PlanosScreen/>}/>
        <Route exact path='/recursos' element={<RecursosScreen/>}/>
        <Route exact path='/fale-conosco' element={<ContatoScreen/>}/>
        <Route exact path='/contratar' element={<ContratarScreen/>}/>
        <Route exact path='/faq' element={<FaqScreen/>}/>
        <Route exact path='/*' element={<Navigate to={'/'}/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
