import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleExclamation, faCircleInfo, faCircleXmark, faXmark } from '@fortawesome/free-solid-svg-icons';
import "../styles/notification.scss";

export default function notification(props) {
    return(
        <div className="notification">
            <FontAwesomeIcon icon={props.tipo === "Sucesso" ? faCircleCheck : props.tipo === "Erro" ? faCircleXmark : props.tipo === "Alerta" ? faCircleExclamation : faCircleInfo}/>
            <p>{props.mensagem}</p>
            <FontAwesomeIcon icon={faXmark} onClick={props.onClose} className='icon-close-notification'/>
        </div>
    )
};