import React, { Fragment } from 'react';
import "../styles/home.scss";
import Dispositivos from "../assets/Mockup.png";
import UsandoCelular from "../assets/usandoCelular.jpg";
import Footer from '../components/footer';
import Header from '../components/header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCalendarDays, faChartLine, faCamera, faCarSide, faEye, faGraduationCap, faHandSparkles, faPlus, faScissors, faScrewdriverWrench, faUserDoctor } from '@fortawesome/free-solid-svg-icons';
import { faCalendarCheck } from '@fortawesome/free-regular-svg-icons';

function HomeScreen () {
    return (
        <Fragment>
            <Header/>
            <main id='page-home'>
                <section id='section-home-introduct'>
                    <div>
                        <h1><span>Gestão eficiente</span> e <span>otimização de tempo</span> para sua empresa</h1>
                        {/* <h4>Tudo que sua empresa precisa para se destacar no m</h4> */}
                        {/* <h4>Transforme a gestão de horários sua empresa em uma experiência eficiente e intuitiva. Potencialize a produtividade com a nossa plataforma projetada para simplificar e aprimorar a organização do seu tempo e fazer você se destacar!</h4> */}
                        <h4>Transforme a gestão de horários da sua empresa em uma experiência eficiente e intuitiva. Potencialize a produtividade com nossa plataforma e se destaque!</h4>
                        <a href='/contratar' target='_blank'>Quero me destacar <span><FontAwesomeIcon icon={faAngleRight}/></span></a>
                    </div>
                    <img src={Dispositivos} alt='Dispositivos eletrônicos com a página de agendamentos da BookingHub na tela'/>
                </section>
                {/* descrição */}
                <section id='section-home-about'>
                    <div className='image-section-about'>
                        <img src={UsandoCelular} alt='Pessoa usando celular, demonstrando o uso do BookingHub em qualquer lugar'/>
                        <div className='object-image1'></div>
                        <div className='object-image2'></div>
                    </div>
                    <div className='texts-section-about'>
                        <h2>Sua empresa merece o melhor</h2>
                        {/* <h3>Conte com uma plataforma poderosa que impulsiona a eficiência, feita exclusivamente para transformar a maneira como você gerencia seu tempo e compromissos. Nós entendemos que cada minuto conta, e é por isso que desenvolvemos uma solução inovadora que simplifica e aprimora o processo de agendamento para você e seus clientes. Tenha ao seu alcance e de forma rápida tudo sobre seu negócio, desde horário que seus clientes estão agendados até o controle financeiro de sua empresa.</h3> */}
                        <h3>Conte com uma plataforma poderosa que impulsiona a eficiência, feita exclusivamente para transformar a maneira como você gerencia seu tempo e compromissos. Nós entendemos que cada minuto conta, e é por isso que desenvolvemos uma solução inovadora que simplifica e aprimora o processo de agendamento para você e seus clientes. Tenha ao seu alcance e de forma rápida tudo sobre seu negócio, desde horário que seus clientes estão agendados até um relatório completo de sua empresa.</h3>
                        {/* <h3>Conte com vários recursos poderosos que impulsionam a eficiência e revolucione a forma de gerenciar horários na sua empresa. Com a BookingHub isso se torna acessível de forma intuitiva e rápida, </h3> */}
                    </div>
                </section>

                {/* recursos */}
                <section id='section-home-features'>
                    <h2>Recursos que fazem a diferença</h2>
                    <h3>Descubra recursos poderosos que impulsionam a eficiência e revolucione a forma de gerenciar horários na sua empresa.</h3>
                    <ul>
                        <li>
                            <FontAwesomeIcon icon={faCalendarDays}/>
                            <h4>Agendamentos</h4>
                            <p>Tenha uma gestão completa dos horários, use filtros de busca, altere o status dos agendamentos e visualize tudo facilmente.</p>
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCalendarCheck}/>
                            <h4>Agenda Rápida</h4>
                            <p>Permita que seus clientes agende seus próprios horários de forma simples, otimizando o seu trabalho e melhorando a experiência do cliente.</p>
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faChartLine}/>
                            <h4>Gestão financeira</h4>
                            <p>Conte com a nossa ajuda para gerenciar as finanças de seu negócio. Calcule as comissões dos profissionais, cadastre contas e muito mais.</p>
                        </li>
                        {/* <li>
                            <FontAwesomeIcon icon={faChartLine}/>
                            <h4>Relatórios</h4>
                            <p>Conte com relatórios completos sobre sua operação, tenha em mãos a quantidade de atendimentos, comparativo entre periodos e muito mais.</p>
                        </li> */}
                        {/* <li>
                            <FontAwesomeIcon icon={faLink}/>
                            <h4>HubLinks</h4>
                            <p>Uma ferramenta para divulgar links em suas redes sociais, coloque desde suas redes sociais até o seu whatsapp comercial.</p>
                        </li> */}
                        {/* <li>
                            <FontAwesomeIcon icon={faFileInvoiceDollar}/>
                            <h4>Financeiro</h4>
                            <p>Faça a gestão financeira de seu estabelecimento direto pela nossa plataforma, facilitando ainda mais o seu dia a dia.</p>
                        </li> */}
                        {/* <li>
                            <FontAwesomeIcon icon={faGears}/>
                            <h4>Serviços</h4>
                            <p>Cadastre todos os serviços que sua empresa oferece e selecione os serão necessários em cada agendamento.</p>
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faUsers}/>
                            <h4>Colaboradores</h4>
                            <p>Conte com a ajuda de seus colaboradores para agendar, cadastrar novos serviços e atualizar status dos horários.</p>
                        </li> */}
                    </ul>
                </section>

                {/* <section id='section-home-about'>
                    {/* <div id="particle-container">
                        <div class="particle"></div>
                        <div class="particle"></div>
                        <div class="particle"></div>
                        <div class="particle"></div>
                        <div class="particle"></div>
                        <div class="particle"></div>
                        <div class="particle"></div>
                        <div class="particle"></div>
                        <div class="particle"></div>
                        <div class="particle"></div>
                        <div class="particle"></div>
                        <div class="particle"></div>
                        <div class="particle"></div>
                        <div class="particle"></div>
                        <div class="particle"></div>
                        <div class="particle"></div>
                        <div class="particle"></div>
                        <div class="particle"></div>
                        <div class="particle"></div>
                        <div class="particle"></div>
                        <div class="particle"></div>
                        <div class="particle"></div>
                        <div class="particle"></div>
                        <div class="particle"></div>
                        <div class="particle"></div>
                        <div class="particle"></div>
                        <div class="particle"></div>
                        <div class="particle"></div>
                        <div class="particle"></div>
                        <div class="particle"></div>
                    </div> */} {/*
                </section> */}

                <section id='section-home-companys'>
                    <h2>Independente do nicho, nós atendemos</h2>
                    <h3>Seja qual for o ramo da sua empresa, nossa solução é para todas as que fazem gestão de horários.<br/>Conheça os principais ramos:</h3>
                    <ul>
                        <li><FontAwesomeIcon icon={faScissors}/>Barbearias</li>
                        <li><FontAwesomeIcon icon={faScissors}/>Salões de Beleza</li>
                        <li><FontAwesomeIcon icon={faCarSide}/>Lava Jatos</li>
                        <li><FontAwesomeIcon icon={faCarSide}/>Estéticas Automotivas</li>
                        <li><FontAwesomeIcon icon={faScrewdriverWrench}/>Mecânicas</li>
                        <li><FontAwesomeIcon icon={faHandSparkles}/>Nail Designers/Manicures</li>
                        <li><FontAwesomeIcon icon={faEye}/>Extensionistas de Cilios</li>
                        <li><FontAwesomeIcon icon={faEye}/>Designers de Sobrancelhas</li>
                        <li><FontAwesomeIcon icon={faGraduationCap}/>Professores/Instrutores</li>
                        <li><FontAwesomeIcon icon={faCamera}/>Fotógrafos</li>
                        <li><FontAwesomeIcon icon={faUserDoctor}/>Psicólogos</li>
                        <li><FontAwesomeIcon icon={faPlus}/>Entre outros</li>
                    </ul>
                </section>

                {/* call to action */}
                <section className='section-cta'>
                    <div>
                        <h2>Maximize sua produtividade e esteja na frente dos demais</h2>
                        <h3>Inscreva-se e receba <span>15 dias grátis</span> para testar!</h3>
                        <a href='/contratar' target='_blank'>Quero me destacar <span><FontAwesomeIcon icon={faAngleRight}/></span></a>
                    </div>
                </section>
            </main>
            <Footer/>
        </Fragment>
    );
};
   
export default HomeScreen;