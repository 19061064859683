import React from 'react';
import "../styles/footer.scss";
import Logo from "../assets/logoEscritaClaro2.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faLinkedin, faTiktok, faXTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';

function Footer () {
    // const navigate = useNavigate();

    return (
        <footer>
            <div className='container-footer-top'>
                <div className='content-footer-company'>
                    <img src={Logo} alt='Logo BookingHub'/>
                    <div>
                        <h5>BookingHub Sistemas</h5>
                        <h6>Av. Paissandú, 526, Sala 16L</h6>
                        <h6>Maringá-PR, 87050-130</h6>
                        <h6><a href='mailto:contato@bookinghub.com.br'>contato@bookinghub.com.br</a></h6>
                        {/* <h6>(44) 9 9157-7967</h6> */}
                    </div>
                </div>
                <div className='content-footer-links'>
                    <ul>
                        <h3>Links Rápidos</h3>
                        <li><a href='/planos-e-precos'>Planos e Preços</a></li>
                        <li><a href='/recursos'>Recursos</a></li>
                        <li><a href='https://parceiros.bookinghub.com.br'>Sou parceiro</a></li>
                        <li><a href='/contratar' target='_blank'>Quero ser parceiro</a></li>
                        <li><a href='https://www.linkedin.com/company/bookinghub/jobs' target='_blank' rel="noopener noreferrer">Nossas vagas</a></li>
                    </ul>
                    <ul>
                        <h3>Suporte</h3>
                        <li><a href='/fale-conosco'>Entre em contato</a></li>
                        <li><a href='https://assets.bookinghub.com.br/termos/termos-de-uso.pdf' target='_blank' rel="noreferrer">Termos e Políticas</a></li>
                        <li><a href='/faq'>FAQ</a></li>
                    </ul>
                    <div>
                        <ul>
                            <h3>Nossas redes</h3>
                            <li><a href='https://instagram.com/bookinghub_br' target='_blank' rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram}/></a></li>
                            <li><a href='https://www.facebook.com/bookinghub.br' target='_blank' rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebookF}/></a></li>
                            <li><a href='https://www.linkedin.com/company/bookinghub' target='_blank' rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin}/></a></li>
                            <li><a href='https://twitter.com/bookinghub_br' target='_blank' rel="noopener noreferrer"><FontAwesomeIcon icon={faXTwitter}/></a></li>
                            <li><a href='https://tiktok.com/@bookinghub_br' target='_blank' rel="noopener noreferrer"><FontAwesomeIcon icon={faTiktok}/></a></li>
                            <li><a href='https://www.youtube.com/@BookingHub_BR' target='_blank' rel="noopener noreferrer"><FontAwesomeIcon icon={faYoutube}/></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='container-footer-bottom'>
                <p>&copy; Copyright {new Date().getFullYear()} - BookingHub - Todos os direitos reservados.</p>
            </div>
        </footer>
    );
};
   
export default Footer;