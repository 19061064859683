import React, { Fragment } from 'react';
import "../styles/header.scss";
import Logo from "../assets/logoEscrita.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

function Header () {
    function scrollF() {
        if (window.scrollY > 20) {
            document.querySelector('header').classList.add('sticky');
        } else {
            document.querySelector('header').classList.remove('sticky');
        }
    };

    window.addEventListener("scroll", scrollF);

    return (
        <Fragment>
            <header>
                <a href='/'><img src={Logo} alt='Logo BookingHub'/></a>
                <nav>
                    <ul>
                        <li>
                            <a href='/planos-e-precos'>Planos e preços</a>
                        </li>
                        <li>
                            <a href='/recursos'>Recursos</a>
                        </li>
                        <li>
                            <a href='/fale-conosco'>Entre em contato</a>
                        </li>
                        <li>
                            <a href='https://parceiros.bookinghub.com.br'>Iniciar sessão</a>
                        </li>
                    </ul>
                    <a href='/contratar' target='_blank'>Conhecer grátis <span><FontAwesomeIcon icon={faAngleRight}/></span></a>
                </nav>
            </header>
            {/* <button id='btn-sou-parceiro'>Já sou parceiro</button> */}
        </Fragment>
    );
};
   
export default Header;