import React, { Fragment, useRef, useState } from 'react';
import "../styles/contato.scss";
import InputMask from 'react-input-mask';
import Footer from '../components/footer';
import Header from '../components/header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';

function ContatoScreen () {
    document.title = `Contatos - BookingHub`;
    const captchaRef = useRef(null);
    const [mensagem, setMensagem] = useState();
    const [valTelefone, setValTelefone] = useState("");

    async function enviarContato(e) {
        e.preventDefault();

        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData);

        data.token = captchaRef.current.getValue()
        
        if (!data.token) {
            setMensagem({tipo: "erro", mensagem: "É necessário resolver o reCaptcha antes de prosseguir."})
        } else {
            try {
                const res = await axios.post(`https://api.bookinghub.com.br/site/enviar-contato`, data);
                captchaRef.current.reset();
                const containerContato = document.querySelectorAll("form");
        
                for (const item of containerContato) {
                    item.reset();
                };

                setValTelefone("");
                setMensagem({tipo: "sucesso", mensagem: "Mensagem enviada com sucesso!"});
                setTimeout(() => {
                    setMensagem();
                }, 10000);
            } catch (error) {
                console.log(error);
                setMensagem({tipo: "erro", mensagem: "Ocorreu um erro, tente novamente!"});
                captchaRef.current.reset();
            }
        }
    };

    return (
        <Fragment>
            <Header/>
            <main id='page-contato'>
                <section id='section-contact-contato'>
                    <h1>Fale com a gente <span>agora mesmo</span>!</h1>
                    <h4>Mande sua mensagem para nós através do formulário abaixo ou fale conosco pelas outras formas de contato</h4>
                    <div>
                        <form onSubmit={enviarContato}>
                            <div style={{width: '49%'}}>
                                <label htmlFor='nome'>Seu nome</label>
                                <input type='text' name='nome' id='nome' autoComplete='off' required/>
                            </div>
                            <div style={{width: '49%'}}>
                                <label htmlFor='email'>Seu melhor e-mail</label>
                                <input type='email' name='email' id='email' autoComplete='off' required/>
                            </div>
                            <div style={{width: '49%'}}>
                                <label htmlFor='telefone'>Telefone</label>
                                <InputMask name='telefone' id='telefone' autoComplete='off' value={valTelefone} onChange={(e) => setValTelefone(e.target.value)} mask="(99) 9 9999-9999" maskChar=" " required/>
                            </div>
                            <div style={{width: '49%'}}>
                                <label htmlFor='empresa'>Empresa</label>
                                <input type='text' name='empresa' id='empresa' autoComplete='off' required/>
                            </div>
                            <div style={{width: '100%'}}>
                                <label htmlFor='mensagem'>Mensagem</label>
                                <textarea name='mensagem' id='mensagem' rows={4} required minLength={50} maxLength={800} autoComplete='off'/>
                            </div>
                            <div className='footer-form'>
                                <ReCAPTCHA sitekey="6LeszX8pAAAAAEyaNJbOBchNUj-wDOnPA6UloaEV" ref={captchaRef}/>
                                {mensagem ? <p style={mensagem.tipo === "sucesso" ? {color: 'green'} : {color: 'red'}}>{mensagem.mensagem}</p> : null}
                                <button type='submit'>Enviar mensagem</button>
                            </div>
                        </form>
                        <div id='other-contacts'>
                            <h5>Contatos</h5>
                            <p>Quer entrar em contato conosco de outra forma? Segue abaixo outras formas:</p>
                            <ul>
                                {/* <li>
                                    <FontAwesomeIcon icon={faPhone}/>
                                    <p><a href='tel:+5544988082004'>(44) 9 8808-2004</a></p>
                                </li> */}
                                <li>
                                    <FontAwesomeIcon icon={faEnvelope}/>
                                    <p><a href='mailto:contato@bookinghub.com.br'>contato@bookinghub.com.br</a></p>
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faLocationDot}/>
                                    <p>Av. Paissandú, 526, Sala 16L<br/>Zona 03, Maringá/PR</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
            </main>
            <Footer/>
        </Fragment>
    );
};
   
export default ContatoScreen;