import React, { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import "../styles/contratar.scss";
import ImagemCelular from "../assets/MockupContratar.png";
import Logo from '../assets/logoEscrita.png';
import InputMask from 'react-input-mask';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faCheckCircle, faChevronDown, faChevronUp, faCircle, faCircleCheck, faSpinner, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { faSquare, faSquareCheck } from '@fortawesome/free-regular-svg-icons';
import Notification from '../components/notification';
import axios from 'axios';
import moment from 'moment';
import { NumericFormat } from 'react-number-format';

function ContratarScreen () {
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    document.title = `Contratar - BookingHub`;
    const [notifications, setNotifications] = useState([]);
    const [tipoConta, setTipoConta] = useState('pj');
    const [etapaCadastro, setEtapaCadastro] = useState(1);
    const [infosCadastro, setInfosCadastro] = useState({
        nome_completo: '',
        email: '',
        senha1: '',
        senha2: '',
        termos_aceitos: false,
        razao_social: '',
        nome_empresa: '',
        estado_empresa: '',
        cidade_empresa: '',
        endereco_empresa: '',
        nicho_empresa: '',
        nome_responsavel: ''
    });
    const [valCNPJEmpresa, setValCNPJEmpresa] = useState("");
    const [valTelefoneEmpresa, setValTelefoneEmpresa] = useState("");
    const [valTelefoneResponsavel, setValTelefoneResponsavel] = useState("");
    const [valCPFResponsavel, setValCPFResponsavel] = useState("");
    const [estadosIBGE, setEstadosIBGE] = useState();
    const [cidadesIBGE, setCidadesIBGE] = useState([]);
    const [dadosEmpresa, setDadosEmpresa] = useState();
    const [codigoEnviado, setCodigoEnviado] = useState();
    const [codigoRecebido, setCodigoRecebido] = useState({codigo1: '', codigo2: '', codigo3: '', codigo4: ''});
    const [emailConfirmado, setEmailConfirmado] = useState({email: ''});
    const [segundosReenviar, setSegundosReenviar] = useState(0);
    const [modalConfirmacao, setModalConfirmacao] = useState(false);
    const [aguardandoButton, setAguardandoButton] = useState(false);
    const [detailsSelected, setDetailsSelected] = useState(null)
    const [nichos, setNichos] = useState();
    const [planos, setPlanos] = useState();
    const [planoEscolhido, setPlanoEscolhido] = useState(query.get('planoSelecionado') && query.get('planoSelecionado'));

    async function getEstadosIBGE() {
        const res = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
        setEstadosIBGE(res.data);
    };

    async function getCidadesIBGE(uf) {
        const res = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`);
        setCidadesIBGE(res.data);
    };

    async function getCNPJ(cnpj) {
        const res = await axios.get(`https://api-publica.speedio.com.br/buscarcnpj?cnpj=${cnpj}`);
        if(res.data["RAZAO SOCIAL"]) {
            setInfosCadastro({...infosCadastro, razao_social: res.data["RAZAO SOCIAL"]})
        }
    };

    async function getNichos() {
        const res = await axios.get('https://api.bookinghub.com.br/site/nichos');
        setNichos(res.data);
    };

    async function getPlanos() {
        const res = await axios.get('https://api.bookinghub.com.br/site/planos');
        setPlanos(res.data);
    };

    function createNotification(tipo, mensagem, tempo) {
        // TIPOS: Sucesso / Erro / Alerta / Info
        let notificacao = []
        notificacao.tipo = tipo;
        notificacao.mensagem = mensagem;
        notificacao.id = notifications.length === 0 ? 1 : notifications.at(-1).id + 1 
        
        setNotifications((prevNotifications) => [...prevNotifications, notificacao]);
        
        setTimeout(() => {
            removeNotification(notificacao.id)
        }, tempo * 1000 || 5000);
    };

    function removeNotification(notificationRemove) {
        setNotifications((prevNotifications) =>
            prevNotifications.filter((notification) => notification.id !== notificationRemove)
        );
    };

    useEffect(() => {
        getNichos();
        getPlanos();
        getEstadosIBGE();
    }, []);

    async function confirmacaoEmail() {
        setCodigoRecebido({codigo1: '', codigo2: '', codigo3: '', codigo4: ''});
        setModalConfirmacao(true);

        const codigo = Math.floor(Math.random() * 9000) + 1000;
        setCodigoEnviado(codigo);

        segundosReenviar === 0 && temporizadorReenvio(60);

        await axios.post(`https://api.bookinghub.com.br/site/confirmar-email?email=${infosCadastro.email}`, {codigo}).then(() => {
        }).catch((error) => {
            createNotification('Erro', "Não foi possível enviar o código de verificação, tente novamente mais tarde.", 7)
        });
    };

    function temporizadorReenvio(tempo) {
        for (let index = tempo; index >= 0; index--) {
            setTimeout((i) => {
                setSegundosReenviar(i);
            }, (tempo - index) * 1000, index);
        };
    };

    function confirmarCodigo(e) {
        e.preventDefault();

        const formData = new FormData(e.target);
        let form = Object.fromEntries(formData);

        const numerosRecebidos = Number(`${form['codigo-number1']}${form['codigo-number2']}${form['codigo-number3']}${form['codigo-number4']}`)
        
        if (numerosRecebidos === codigoEnviado) {
            setEtapaCadastro(2);
            setModalConfirmacao(false);
            setEmailConfirmado({email: infosCadastro.email});
        } else {
            createNotification('Erro', "O código digitado é inválido.");
        }
    };

    async function verificacaoForm(e) {
        e.preventDefault();
        
        switch (true) {
            case etapaCadastro === 1:
                if (infosCadastro.nome_completo === '' || infosCadastro.nome_completo.length < 3 || infosCadastro.nome_completo.length > 50 || infosCadastro.email === '' || infosCadastro.email.length < 6 || infosCadastro.senha1 === '' || infosCadastro.senha1.length < 6 || infosCadastro.senha1.length > 30 || infosCadastro.senha2 === '' || infosCadastro.senha2.length < 6 || infosCadastro.senha2.length > 30) {
                    
                    // if (infosCadastro.nome_completo === '' || infosCadastro.nome_completo.length < 3 || infosCadastro.nome_completo.length > 50) {
                    //     document.querySelector("#nome_completo").classList.add('invalid-box');
                    // };

                    // if (infosCadastro.email === '' || infosCadastro.email.length < 6) {
                        
                    // };

                    // if (infosCadastro.senha1 === '' || infosCadastro.senha1.length < 6 || infosCadastro.senha1.length > 30) {
                        
                    // };

                    // if (infosCadastro.senha2 === '' || infosCadastro.senha2.length < 6 || infosCadastro.senha2.length > 30) {
                        
                    // };

                    createNotification('Erro', "Existem campos preenchidos incorretamente.", 6);
                } else if (infosCadastro.senha1 !== infosCadastro.senha2) {
                    createNotification('Erro', "As duas senhas digitadas não correspondem.", 6)
                } else if (infosCadastro.termos_aceitos === false) {
                    createNotification('Erro', "É necessário aceitar os termos de uso.")
                } else {
                    axios.get(`https://api.bookinghub.com.br/site/verificar-email?email=${infosCadastro.email}`).then(() => {
                        if (emailConfirmado.email !== infosCadastro.email) {
                            confirmacaoEmail();
                        } else {
                            setEtapaCadastro(2);
                        };
                    }).catch((error) => {
                        if (error.response.status === 401) {
                            createNotification('Erro', "E-mail já cadastrado.")
                        } else {
                            createNotification('Erro', "Ocorreu um erro, tente novamente mais tarde.", 6)
                        }
                    });
                };
            break;
            case etapaCadastro === 2:
                if (
                    infosCadastro.nome_empresa.length < 3 || infosCadastro.nome_empresa.length > 60 ||
                    valTelefoneEmpresa.replace(/\s|\(|\)|-/g, '').length < 10 ||
                    infosCadastro.estado_empresa === '' || infosCadastro.cidade_empresa === '' ||
                    infosCadastro.endereco_empresa.length < 5 || infosCadastro.endereco_empresa.length > 150 ||
                    infosCadastro.nicho_empresa === '' ||
                    (tipoConta === 'pj' && (valCNPJEmpresa.replace(/[./-]/g, '').length < 14 || infosCadastro.razao_social.length < 5 || infosCadastro.razao_social.length > 100))
                ){
                    createNotification('Erro', "Existem campos preenchidos incorretamente.", 6);
                } else {
                    if (tipoConta === 'pj') {
                        axios.get(`https://api.bookinghub.com.br/site/verificar-cnpj?cnpj=${valCNPJEmpresa.replace(/[./-]/g, '')}`).then(() => {
                            setEtapaCadastro(3);
                        }).catch((error) => {
                            if (error.response.status === 401) {
                                createNotification('Erro', "CNPJ já cadastrado.")
                            } else {
                                createNotification('Erro', "Ocorreu um erro, tente novamente mais tarde.")
                            }
                        });
                    } else {
                        setEtapaCadastro(3);
                    }
                };
            break;
            case etapaCadastro === 3:
                if (infosCadastro.nome_responsavel.length < 5 || infosCadastro.nome_responsavel.length > 80 ||
                    valCPFResponsavel.replace(/[.-]/g, '').length < 11 ||
                    valTelefoneResponsavel.replace(/\s|\(|\)|-/g, '').length < 11
                ) {
                    createNotification('Erro', "Existem campos preenchidos incorretamente.", 6);
                } else {
                    setEtapaCadastro(4);
                };
            break;
            case etapaCadastro === 4:
                if (planoEscolhido === '') {
                    createNotification('Erro', "Selecione um plano para continuar.", 7);
                } else {
                    const empresa = {
                        nome: infosCadastro.nome_empresa,
                        tipo_empresa: tipoConta === 'pj' ? "Pessoa Jurídica" : "Pessoa Física",
                        responsavel: {
                            nome: infosCadastro.nome_responsavel,
                            cpf: valCPFResponsavel.replace(/[.-]/g, ''),
                            telefone: valTelefoneResponsavel.replace(/\s|\(|\)|-/g, '')
                        },
                        dados: {
                            localizacao: {
                                endereco: infosCadastro.endereco_empresa,
                                cidade: undefined,
                            },
                            telefones: [valTelefoneEmpresa.replace(/\s|\(|\)|-/g, '')],
                        },
                        categoria: infosCadastro.nicho_empresa,
                        periodo_teste: {
                            fim: new Date(moment().add(15, 'days').set({hour: 23, minute: 59, second: 59}))
                        },
                        faturamento: {
                            contratacao: {
                                plano: planoEscolhido
                            }
                        }
                    };

                    const parceiro = {
                        nome: infosCadastro.nome_completo,
                        email: infosCadastro.email,
                        password: infosCadastro.senha1,
                        termos: {
                            aceito: true,
                            data_aceite: new Date()
                        }
                    };

                    const cidade = {
                        nome: infosCadastro.cidade_empresa,
                        estado: {
                            nome: estadosIBGE.filter(({sigla}) => sigla === infosCadastro.estado_empresa)[0].nome,
                            uf: infosCadastro.estado_empresa
                        }
                    };

                    if (tipoConta === 'pj') {
                        empresa.razao_social = infosCadastro.razao_social;
                        empresa.cnpj = valCNPJEmpresa.replace(/[./-]/g, '')
                    };

                    if (localStorage.getItem('indication')) {
                        const ref = JSON.parse(localStorage.getItem('indication'));
                
                        if (moment(ref.valid).format("DD/MM/YYYY HH:mm") < moment().format("DD/MM/YYYY HH:mm")) {
                            localStorage.removeItem('indication');
                        } else {
                            empresa.indicacao = ref.affiliate;
                        };
                    };

                    setAguardandoButton(true);

                    try {
                        await axios.get(`https://api.bookinghub.com.br/site/verificar-email?email=${parceiro.email}`);
                        tipoConta === "pj" && await axios.get(`https://api.bookinghub.com.br/site/verificar-cnpj?cnpj=${valCNPJEmpresa.replace(/[./-]/g, '')}`);

                        await axios.post('https://api.bookinghub.com.br/site/contratar', {parceiro, empresa, cidade});

                        setEtapaCadastro(5);
                        setAguardandoButton(false);
                    } catch (error) {
                        if (error.response) {
                            createNotification('Erro', error.response.data.message);
                        } else if (error.request) {
                            createNotification('Erro', 'Erro de requisição. Tente novamente mais tarde.');
                        } else {
                            createNotification('Erro', 'Ocorreu um erro. Tente novamente mais tarde.');
                        }
                        setAguardandoButton(false);
                    };
                };
            break;
            default:
            break;
        }
    };

    function pularInput(e, inputIdAnt, inputIdProx) {
        // setMensagem("");
        if (e.keyCode === 8) {
            document.getElementById(inputIdAnt).focus();
        } else if ((e.keyCode >= 48 & e.keyCode <= 57) || (e.keyCode >= 96 & e.keyCode <= 105)){
            document.getElementById(inputIdProx).focus();
        }
    };

    const toggleDetails = (i) => {
        if (detailsSelected === i) {
            setDetailsSelected(null);
        } else {
            setDetailsSelected(i)
        }
    };

    function ordemAlfabetica(a, b) {
        // return a.nome < b.nome ? -1 : a.nome > b.nome ? 1 : 0;

        if (a.nome === "Outros" && b.nome !== "Outros") {
            return 1; // "Outros" vem depois de qualquer outro item
        } else if (a.nome !== "Outros" && b.nome === "Outros") {
            return -1; // "Outros" vem antes de qualquer outro item
        } else {
            return a.nome.localeCompare(b.nome); // Comparação padrão para os outros itens
        }
    };

    function ordemPreco(a, b) {
        return a.valores.mensal < b.valores.mensal ? -1 : a.valores.mensal > b.valores.mensal ? 1 : 0;
    };

// INDICAÇÃO
    if (query.get('ref')) {
        localStorage.setItem('indication', JSON.stringify({affiliate: query.get('ref'), valid: moment().add(7, 'days')}));
    };

    return (
        <main id='page-contratar'>
            <section id='section-contratar-image'>
                {/* <div> */}
                    {/* <img src={Logo} alt='Imagem de uma pessoa utilizando a plataforma BookingHub em um smartphone'/>
                    <h1>Inicie seu teste <span>grátis</span> de 15 dias!</h1> */}
                    <img src={ImagemCelular} alt='Imagem de uma pessoa utilizando a plataforma BookingHub em um smartphone'/>
                {/* </div> */}
            </section>
            <section id='section-contratar-forms'>
                { etapaCadastro !== 5 ?
                    <Fragment>
                        <h1>Inicie seu teste <span>grátis</span> de 15 dias!</h1>
                        <div className="stages-create-account">
                            <div className={`stage-create-account ${etapaCadastro === 1 && 'selected-stage-account'}`}>
                                <FontAwesomeIcon icon={etapaCadastro > 1 ? faCircleCheck : faCircle}>1</FontAwesomeIcon>
                                <div className={`bar-stage-create-account bar-right-stage-account ${etapaCadastro > 1 && 'bar-finish-stage-account'}`}/>
                            </div>
                            <div className={`stage-create-account ${etapaCadastro === 2 && 'selected-stage-account'}`}>
                                <div className={`bar-stage-create-account bar-left-stage-account ${etapaCadastro > 1 && 'bar-finish-stage-account'}`}/>
                                <FontAwesomeIcon icon={etapaCadastro > 2 ? faCircleCheck : faCircle}/>
                                <div className={`bar-stage-create-account bar-right-stage-account ${etapaCadastro > 2 && 'bar-finish-stage-account'}`}/>
                            </div>
                            <div className={`stage-create-account ${etapaCadastro === 3 && 'selected-stage-account'}`}>
                                <div className={`bar-stage-create-account bar-left-stage-account ${etapaCadastro > 2 && 'bar-finish-stage-account'}`}/>
                                <FontAwesomeIcon icon={etapaCadastro > 3 ? faCircleCheck : faCircle}/>
                                <div className={`bar-stage-create-account bar-right-stage-account ${etapaCadastro > 3 && 'bar-finish-stage-account'}`}/>
                            </div>
                            <div className={`stage-create-account ${etapaCadastro === 4 && 'selected-stage-account'}`}>
                                <div className={`bar-stage-create-account bar-left-stage-account ${etapaCadastro > 3 && 'bar-finish-stage-account'}`}/>
                                <FontAwesomeIcon icon={etapaCadastro > 4 ? faCircleCheck : faCircle}/>
                            </div>
                        </div>
                    </Fragment>
                :
                    null
                }
                <div className='info-steps-account'>
                    {(() => {
                        switch (true) {
                            case etapaCadastro === 1:
                                return(<h2>Dados de Acesso</h2>)
                            case etapaCadastro === 2:
                                return(<h2>Informações da empresa</h2>)
                            case etapaCadastro === 3:
                                return(<h2>Responsável pela empresa</h2>)
                            case etapaCadastro === 4:
                                return(<h2>Plano de contratação</h2>)
                            case etapaCadastro === 5:
                                return(<h2>Cadastro finalizado!</h2>)
                            default:
                                break;
                        }
                    })()}
                    {/* <p>Para começar, vamos precisar de algumas informações para cadastro da sua conta.</p> */}
                </div>
                {(() => {
                    switch (true) {
                        case etapaCadastro === 1:
                            return(
                                <form onSubmit={verificacaoForm}>
                                    <div className={`campos-form`}>
                                        <div style={{width: '100%'}}>
                                            <label htmlFor='nome_completo'>Nome completo</label>
                                            <input type='text' id='nome_completo' name='nome_completo' minLength={3} maxLength={50} onChange={(e) => setInfosCadastro({...infosCadastro, nome_completo: e.target.value})} defaultValue={infosCadastro.nome_completo} required/>
                                        </div>
                                        <div style={{width: '100%'}}>
                                            <label htmlFor='email'>Seu melhor e-mail</label>
                                            <input type='email' id='email' name='email' minLength={6} onChange={(e) => setInfosCadastro({...infosCadastro, email: e.target.value})} defaultValue={infosCadastro.email} required/>
                                        </div>
                                        <div style={{width: '48%'}}>
                                            <label htmlFor='senha1'>Crie uma senha</label>
                                            <input type='password' id='senha1' name='senha1' min={6} max={30} onChange={(e) => setInfosCadastro({...infosCadastro, senha1: e.target.value})} defaultValue={infosCadastro.senha1} required/>
                                        </div>
                                        <div style={{width: '48%'}}>
                                            <label htmlFor='senha2'>Confirme sua senha</label>
                                            <input type='password' id='senha2' name='senha2' min={6} max={30} onChange={(e) => setInfosCadastro({...infosCadastro, senha2: e.target.value})} defaultValue={infosCadastro.senha2} required/>
                                        </div>
                                        <div style={{width: '100%', marginTop: '3vh', flexDirection: 'row', alignItems: 'center'}}>
                                            <input type='checkbox' id='termos' name='termos' onChange={(e) => setInfosCadastro({...infosCadastro, termos_aceitos: e.target.checked})} defaultChecked={infosCadastro.termos_aceitos} required/>
                                            <label style={{margin: '0 0 0 .5em'}}>Declaro que aceito os <a href='https://assets.bookinghub.com.br/termos/termos-de-uso.pdf' target='_blank' rel="noreferrer" style={{textDecoration: 'underline', color: '#0071ae', cursor: 'pointer'}}>termos de uso</a></label>
                                        </div>
                                    </div>
                                    <div className='btn-toolbar' style={{justifyContent: 'flex-end'}}>
                                        <button className='btn-avancar' type='submit' key={'btn-avancar'}>Avançar <span><FontAwesomeIcon icon={faAngleRight}/></span></button>
                                    </div>
                                </form>
                            )
                        case etapaCadastro === 2:
                            return(
                                <form onSubmit={verificacaoForm}>
                                    <div className={`campos-form`}>
                                        <div className='input-radio-account'>
                                            <div className={tipoConta === 'pj' ? 'radio-account-active' : ""} onClick={() => setTipoConta('pj')}>Sou Pessoa Jurídica</div>
                                            <div className={tipoConta === 'pf' ? 'radio-account-active' : ""} onClick={() => setTipoConta('pf')}>Sou Pessoa Física</div>
                                        </div>
                                        { tipoConta === 'pj' &&
                                            <Fragment>
                                                <div style={{width: '33%'}}>
                                                    <label htmlFor='cnpj'>CNPJ</label>
                                                    <InputMask name='cnpj' id='cnpj' value={valCNPJEmpresa} onBlur={(e) => {const cnpjTratado = e.target.value.replace(/[./-]/g, ''); if (cnpjTratado.length < 14) { setDadosEmpresa() } else { getCNPJ(cnpjTratado) }; }} onChange={(e) => setValCNPJEmpresa(e.target.value)} minLength={18} mask="99.999.999/9999-99" maskChar={null} required/>
                                                </div>
                                                <div style={{width: '64%'}}>
                                                    <label htmlFor='razao_social'>Razão Social</label>
                                                    <input type='text' id='razao_social' name='razao_social' minLength={5} maxLength={100} required onChange={(e) => setInfosCadastro({...infosCadastro, razao_social: e.target.value})} defaultValue={infosCadastro.razao_social}/>
                                                </div>
                                            </Fragment>
                                        }
                                        <div style={{width: '63%'}}>
                                            <label htmlFor='nome_empresa'>Nome do negócio</label>
                                            <input type='text' id='nome_empresa' name='nome_empresa' minLength={3} maxLength={60} key={'nome_empresa'} onChange={(e) => setInfosCadastro({...infosCadastro, nome_empresa: e.target.value})} defaultValue={infosCadastro.nome_empresa} required/>
                                        </div>
                                        <div style={{width: '34%'}}>
                                            <label htmlFor='telefone_empresa'>Telefone / Celular</label>
                                            <InputMask name='telefone_empresa' id='telefone_empresa' value={valTelefoneEmpresa} onChange={(e) => setValTelefoneEmpresa(e.target.value)} mask={valTelefoneEmpresa.replace(/\s|\(|\)|-/g, '').length <= 10 ? "(99) 9999-99999" : "(99) 9 9999-9999"} maskChar={null} required/>
                                        </div>
                                        <div style={{width: '32%'}}>
                                            <label htmlFor='estado_empresa'>Estado</label>
                                            <select id='estado_empresa' name='estado_empresa' defaultValue={infosCadastro.estado_empresa} required onChange={(e) => {getCidadesIBGE(e.target.value); setInfosCadastro({...infosCadastro, estado_empresa: e.target.value})}}>
                                                <option value="" disabled selected>Selecione</option>
                                                {estadosIBGE?.sort(ordemAlfabetica).map((item, index) => (
                                                    <option key={index} value={item.sigla}>{item.nome}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div style={{width: '65%'}}>
                                            <label htmlFor='cidade_empresa'>Cidade</label>
                                            <select id='cidade_empresa' name='cidade_empresa' onChange={(e) => setInfosCadastro({...infosCadastro, cidade_empresa: e.target.value})} defaultValue={infosCadastro.cidade_empresa} required>
                                                <option value="" disabled selected>Selecione {cidadesIBGE.length === 0 && "um estado"}</option>
                                                {cidadesIBGE?.sort(ordemAlfabetica).map((item, index) => (
                                                    <option key={index} value={item.nome}>{item.nome}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div style={{width: '100%'}}>
                                            <label htmlFor='endereco_empresa'>Endereço</label>
                                            <input type='text' id='endereco_empresa' name='endereco_empresa' minLength={5} maxLength={150} onChange={(e) => setInfosCadastro({...infosCadastro, endereco_empresa: e.target.value})} defaultValue={infosCadastro.endereco_empresa} required/>
                                        </div>
                                        <div style={{width: '100%'}}>
                                            <label htmlFor='nicho_empresa'>Qual sua área de atuação?</label>
                                            <select id='nicho_empresa' name='nicho_empresa' onChange={(e) => setInfosCadastro({...infosCadastro, nicho_empresa: e.target.value})} defaultValue={infosCadastro.nicho_empresa} required>
                                                <option value="" disabled selected>Selecione</option>
                                                {nichos?.sort(ordemAlfabetica).map((item, index) => (
                                                    <option key={index} value={item._id}>{item.nome}</option>
                                                ))}
                                            </select>
                                        </div>
                                        { tipoConta === 'pf' &&
                                            <h6>** Contas registradas como pessoa física terão o prazo de 1 ano para alteração para pessoa jurídica.</h6>
                                        }
                                    </div>
                                    <div className='btn-toolbar'>
                                        <button className='btn-voltar' type='button' key={'btn-voltar'} onClick={() => setEtapaCadastro(etapaCadastro - 1)}><span><FontAwesomeIcon icon={faAngleLeft}/></span> Voltar</button>
                                        <button className='btn-avancar' type='submit' key={'btn-avancar'}>Avançar <span><FontAwesomeIcon icon={faAngleRight}/></span></button>
                                    </div>
                                </form>
                            )
                        case etapaCadastro === 3:
                            return(
                                <form onSubmit={verificacaoForm}>
                                    <div className={`campos-form`}>
                                        <div style={{width: '100%'}}>
                                            <label htmlFor='nome_responsavel'>Nome completo</label>
                                            <input type='text' id='nome_responsavel' name='nome_responsavel' minLength={5} maxLength={80} onChange={(e) => setInfosCadastro({...infosCadastro, nome_responsavel: e.target.value})} defaultValue={infosCadastro.nome_responsavel} required/>
                                        </div>
                                        <div style={{width: '52%'}}>
                                            <label htmlFor='cpf_responsavel'>CPF</label>
                                            <InputMask name='cpf_responsavel' id='cpf_responsavel' value={valCPFResponsavel} onChange={(e) => setValCPFResponsavel(e.target.value)} mask="999.999.999-99" maskChar={null} required/>
                                        </div>
                                        <div style={{width: '45%'}}>
                                            <label htmlFor='telefone_responsavel'>Celular com DDD</label>
                                            <InputMask name='telefone_responsavel' id='telefone_responsavel' value={valTelefoneResponsavel} onChange={(e) => setValTelefoneResponsavel(e.target.value)} mask="(99) 9 9999-9999" maskChar={null} required/>
                                        </div>
                                    </div>
                                    <div className='btn-toolbar'>
                                        <button className='btn-voltar' type='button' key={'btn-voltar'} onClick={() => setEtapaCadastro(etapaCadastro - 1)}><span><FontAwesomeIcon icon={faAngleLeft}/></span> Voltar</button>
                                        <button className='btn-avancar' type='submit' key={'btn-avancar'}>Avançar <span><FontAwesomeIcon icon={faAngleRight}/></span></button>
                                    </div>
                                </form>
                            )
                        case etapaCadastro === 4:
                            return(
                                <form onSubmit={verificacaoForm}>
                                    <h3>Escolha um plano para realizar o teste de 15 dias, após esse período será possível alterar ou manter o mesmo plano.</h3>
                                    {/* <h3>O plano escolhido será para os 15 dias grátis, após o período será possível alterar ou manter a mesma escolha.</h3> */}
                                    <div className={`campos-form`}>
                                        <div style={{width: '100%'}}>
                                            {planos.sort(ordemPreco).map((item, index) => (
                                                <div className='radio-plans' key={index}>
                                                    <input type='radio' name='plans' id={item._id} defaultChecked={planoEscolhido === item._id} onChange={(e) => { if (e.target.checked === true){ setPlanoEscolhido(item._id) }}}/>
                                                    <label htmlFor={item._id}>
                                                        <div className='title-label-radio'>
                                                            <h4>{item.nome}</h4>
                                                            <FontAwesomeIcon icon={planoEscolhido === item._id ? faSquareCheck : faSquare}/>
                                                        </div>
                                                        <div>
                                                            <h5>
                                                                <NumericFormat value={item.valores.mensal} displayType="text" decimalScale={2} decimalSeparator="," thousandSeparator="." prefix='R$' fixedDecimalScale/>
                                                                {/* <span>/mês</span> */}
                                                                R$0,00
                                                            </h5>
                                                            <p>/mês</p>
                                                        </div>
                                                        <p>{item.descricao}</p>
                                                        <button onClick={(e) => {e.preventDefault(); toggleDetails(item._id)}}><FontAwesomeIcon icon={detailsSelected === item._id ? faChevronUp : faChevronDown}/>Mais detalhes</button>
                                                        <ul className={detailsSelected === item._id ? 'content-details show-content-details' : 'content-details'}>
                                                            <li>
                                                                <FontAwesomeIcon icon={faCheckCircle}/>
                                                                Agendamentos ilimitados
                                                            </li>
                                                            <li>
                                                                <FontAwesomeIcon icon={faCheckCircle}/>
                                                                Acesso em multiplataformas
                                                            </li>
                                                            <li>
                                                                <FontAwesomeIcon icon={faCheckCircle}/>
                                                                {item.beneficios.colaboradores} Profissionais
                                                            </li>
                                                            <li>
                                                                <FontAwesomeIcon icon={faCheckCircle}/>{item.beneficios.servicos} Serviços
                                                            </li>
                                                            <li>
                                                                <FontAwesomeIcon icon={item.beneficios.permitir_agendamento ? faCheckCircle :faXmarkCircle}/>
                                                                Agenda rápida
                                                            </li>
                                                            {/* <div>
                                                                <FontAwesomeIcon icon={item.beneficios.hublinks ? faCheckCircle :faXmarkCircle}/>
                                                                <p>HubLinks</p>
                                                            </div> */}
                                                            <li>
                                                                <FontAwesomeIcon icon={item.beneficios.relatorios ? faCheckCircle :faXmarkCircle}/>
                                                                Relatórios do negócio
                                                            </li>
                                                            <li>
                                                                <FontAwesomeIcon icon={item.beneficios.financeiro ? faCheckCircle :faXmarkCircle}/>
                                                                <p>Gestão financeira</p>
                                                            </li>
                                                            {/* <div>
                                                                <FontAwesomeIcon icon={faXmarkCircle}/>
                                                                <p>Aviso via SMS e E-mail</p>
                                                            </div> */}
                                                        </ul>
                                                    </label>
                                                </div>
                                            ))}
                                            {/* <div className='radio-plans'>
                                                <input type='radio' name='plans' id='radio1' defaultChecked={planoEscolhido === 1} onChange={(e) => { if (e.target.checked === true){ setPlanoEscolhido(1) }}}/>
                                                <label htmlFor='radio1'>
                                                    <div className='title-label-radio'>
                                                        <h4>Básico</h4>
                                                        <FontAwesomeIcon icon={planoEscolhido === 1 ? faSquareCheck : faSquare}/>
                                                    </div>
                                                    <h5>R$99,90<span>/mês</span></h5>
                                                    <p>Plano feito para negócios pequenos, com poucos serviços e menor quantidade de colaboradores.</p>
                                                </label>
                                            </div>

                                            <div className='radio-plans'>
                                                <input type='radio' name='plans' id='radio2' defaultChecked={planoEscolhido === 2} onChange={(e) => { if (e.target.checked === true){ setPlanoEscolhido(2) }}}/>
                                                <label htmlFor='radio2'>
                                                    <div className='title-label-radio'>
                                                        <h4>Plus</h4>
                                                        <FontAwesomeIcon icon={planoEscolhido === 2 ? faSquareCheck : faSquare}/>
                                                    </div>
                                                    <h5>R$149,90<span>/mês</span></h5>
                                                    <p>Ideal para empresas que procuram um serviço mais completo, contando com relatórios da empresa, mais colaboradores e serviços oferecidos.</p>
                                                </label>
                                            </div> */}
                                        </div>
                                        <h6>** Os valores apresentados são de planos mensais, em planos anuais existe um desconto.</h6>
                                    </div>
                                    <div className='btn-toolbar'>
                                        <button className='btn-voltar' type='button' key={'btn-voltar'} onClick={() => setEtapaCadastro(etapaCadastro - 1)}><span><FontAwesomeIcon icon={faAngleLeft}/></span> Voltar</button>
                                        {!aguardandoButton ? 
                                            <button className='btn-avancar' type='submit' key={'btn-avancar'}>Finalizar <span><FontAwesomeIcon icon={faAngleRight}/></span></button>
                                        :
                                            <button className='btn-avancar' type='button' key={'btn-aguardando'}>Finalizando <FontAwesomeIcon icon={faSpinner} spin style={{marginLeft: '.5em'}}/></button>
                                        }
                                    </div>
                                </form>
                            )
                        case etapaCadastro === 5:
                            return(
                                <Fragment>
                                    <img src={Logo} alt='Logo BookingHub'/>
                                    <h3 style={{width: '80%'}}>Agora você está pronto(a) para desbravar todas as nossas ferramentas incríveis e fazer uma gestão eficiente do seu negócio!<br/><br/>Clique no botão abaixo para ser redirecionado(a) ao login e conheça tudo que podemos te oferecer:</h3>
                                    <a className='btn-login' href='https://parceiros.bookinghub.com.br/login'>Faça login agora</a>
                                </Fragment>
                            )
                        default:
                            break;
                    }
                })()}
            </section>
            { modalConfirmacao ?
                <div className='modal' onClick={() => setModalConfirmacao(false)}>
                    <div className='content-modal' onClick={(e) => e.stopPropagation()}>
                        <FontAwesomeIcon icon={faXmark} onClick={() => setModalConfirmacao(false)}/>
                        <h6>Confirme seu e-mail</h6>
                        <p>Foi enviado um e-mail para <span>{infosCadastro.email}</span>, digite o código de confirmação recebido:</p>
                        <form onSubmit={confirmarCodigo} autoComplete>
                            <div>
                                <input autoComplete="on" style={{ display: 'none' }} id="fake-hidden-input-to-stop-google-address-lookup"/>
                                <InputMask mask={'9'} maskChar={null} name='codigo-number1' id='codigo-number1' required autoComplete='new-password' value={codigoRecebido.codigo1} onChange={(e) => setCodigoRecebido({...codigoRecebido, codigo1: e.target.value})} onKeyUp={(e) => pularInput(e, "codigo-number1", "codigo-number2")}/>
                                <InputMask mask={'9'} maskChar={null} name='codigo-number2' id='codigo-number2' required autoComplete='new-password' value={codigoRecebido.codigo2} onChange={(e) => setCodigoRecebido({...codigoRecebido, codigo2: e.target.value})} onKeyUp={(e) => pularInput(e, "codigo-number1", "codigo-number3")}/>
                                <InputMask mask={'9'} maskChar={null} name='codigo-number3' id='codigo-number3' required autoComplete='new-password' value={codigoRecebido.codigo3} onChange={(e) => setCodigoRecebido({...codigoRecebido, codigo3: e.target.value})} onKeyUp={(e) => pularInput(e, "codigo-number2", "codigo-number4")}/>
                                <InputMask mask={'9'} maskChar={null} name='codigo-number4' id='codigo-number4' required autoComplete='new-password' value={codigoRecebido.codigo4} onChange={(e) => setCodigoRecebido({...codigoRecebido, codigo4: e.target.value})} onKeyUp={(e) => pularInput(e, "codigo-number3", "codigo-number4")}/>
                            </div>
                            <div className='btn-toolbar'>
                                <button type='reset' onClick={() => {if (segundosReenviar === 0){ confirmacaoEmail() }} } disabled={segundosReenviar === 0 ? false : true}>Reenviar código{segundosReenviar !== 0 && ` (${segundosReenviar})`}</button>
                                <button type='submit'>Confirmar</button>
                            </div>
                        </form>
                    </div>
                </div>
            :
                null
            }
            { notifications.length !== 0 ?
                <div className='area-notification'>
                    {notifications.map((item, index) =>(
                        <Notification tipo={item.tipo} titulo={item.titulo} mensagem={item.mensagem} onClose={() => removeNotification(item.id)} key={index}/>
                    ))}
                </div>
            : null }
        </main>
        
    );
};
   
export default ContratarScreen;